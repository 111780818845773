import React from "react";
import "./Fifth.scss";
import Graph from "../Graph";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import { Carousel } from "react-responsive-carousel";
import {
  pathways,
} from "../../../Assets";

const Fifth = (props) => {
  const imgs = [];

  console.log("Infith");
  console.log(props?.data[1]);

  let graphProp = props?.data[1];
  
  if(props?.data[0]) {
    Object.keys(props?.data[0]).forEach(key => {
        if (props?.data[0][key]?.filename != null) {
          imgs.push(props?.data[0][key]?.filename);
        }
    });
  }
  


  const numberOfComponents = 10; // Change this to the desired number of repetitions
  const logoArray1 = [
    "https://www.pngmart.com/files/8/Amazon-PNG-Pic.png",
    "https://www.freepnglogos.com/uploads/flipkart-logo-png/flipkart-logo-transparent-vector-3.png",
    "https://1000logos.net/wp-content/uploads/2021/04/Adobe-logo.png",
    "https://download.logo.wine/logo/Oracle_Cloud_Platform/Oracle_Cloud_Platform-Logo.wine.png",
    "https://seekvectorlogo.com/wp-content/uploads/2019/11/informatica-vector-logo.png",
    "https://download.logo.wine/logo/Zoho_Corporation/Zoho_Corporation-Logo.wine.png",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Freshworks-vector-logo.svg/2048px-Freshworks-vector-logo.svg.png",
    "https://1000logos.net/wp-content/uploads/2020/04/Commscope-Logo.png",
    "https://images.hasgeek.com/embed/file/a0baca393d534736b152750c7bde97f1",
    "https://devrev.ai/images/og-image.png",
    "https://arizent.brightspotcdn.com/dims4/default/817e023/2147483647/strip/true/crop/498x210+0+0/resize/840x354!/quality/90/?url=https%3A%2F%2Fsource-media-brightspot.s3.amazonaws.com%2F94%2F11%2F884ef3324e1aba53216aaa32afa9%2Ftiger-analytics.png",
    "https://mms.businesswire.com/media/20200902005342/en/817754/23/Mr._Cooper_logo_thumbnail.jpg",
    "https://download.logo.wine/logo/Robert_Bosch_GmbH/Robert_Bosch_GmbH-Logo.wine.png",
  ];
  const logoArray2 = [
    "https://vtlogo.com/wp-content/uploads/2021/07/temenos-vector-logo.png",
    "https://cionews.co.in/wp-content/uploads/2022/08/Agg-6-7.png",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b1/Tata_Consultancy_Services_Logo.svg/1200px-Tata_Consultancy_Services_Logo.svg.png",
    "https://1000logos.net/wp-content/uploads/2021/08/Capgemini-Logo.png",
    "https://w7.pngwing.com/pngs/926/247/png-transparent-wipro-consumer-care-lighting-ltd-job-business-information-technology-business-text-service-people.png",
    "https://download.logo.wine/logo/Athenahealth/Athenahealth-Logo.wine.png",
    "https://dl6pgk4f88hky.cloudfront.net/2021/12/Hexaware-logo.png",
    "https://mma.prnewswire.com/media/660270/Rfpio_logo_green_blue_Logo.jpg?p=facebook",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Infosys_logo.svg/1280px-Infosys_logo.svg.png",
    "https://kebs.ai/wp-content/uploads/2023/06/kaar-removebg-preview.png",
    "https://www.grootan.com/static/og-grootan-ba42b86aed1a71bc869263782bd6aaf4.jpg",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREcBA0gd1Xzr3UXuOh8UqSrpzVpnAV6nv6JQ&s",
    // "https://signeasy.com/blog/wp-content/uploads/2021/11/Rectangle-blue-logo-1-1024x353.png",
    "https://allvectorlogo.com/img/2016/10/virtusa-logo.png",
  ];
  const components = [];
  for (let i = 0; i < numberOfComponents; i++) {
    components.push(<img src={pathways} alt="path" />);
  }

  

  return (
    <div className="Fifth">
      <div className="Student">
        <h1>
          Our Commitment Towards Mentorship <br /> Makes Us Unique
        </h1>
        <div className="card_contain">
          <Carousel
            showThumbs={false}
            showStatus={false}
            showArrows={true}
            infiniteLoop={true}
            useKeyboardArrows
            autoPlay
            interval={4000}
            transitionTime={500}
          >
            <div className="carousel-slide">
              <a href={imgs[0]} target="_blank" rel="noopener noreferrer">
                <img src={imgs[0]} alt="Amazon" />
              </a>
              <a href={imgs[4]} target="_blank" rel="noopener noreferrer">
                <img src={imgs[4]} alt="Informatica" />
              </a>
            </div>

            <div className="carousel-slide">
              <a href={imgs[6]} target="_blank" rel="noopener noreferrer">
                <img src={imgs[6]} alt="Freshworks" />
              </a>
              <a href={imgs[7]} target="_blank" rel="noopener noreferrer">
                <img src={imgs[7]} alt="Commscope" />
              </a>
            </div>
            <div className="carousel-slide">
              <a href={imgs[2]} target="_blank" rel="noopener noreferrer">
                <img src={imgs[2]} alt="Adobe" />
              </a>
              <a href={imgs[5]} target="_blank" rel="noopener noreferrer">
                <img src={imgs[5]} alt="ZOHO" />
              </a>
            </div>
            <div className="carousel-slide">
              <a href={imgs[1]} target="_blank" rel="noopener noreferrer">
                <img src={imgs[1]} alt="Flipkart" />
              </a>
              <a href={imgs[9]} target="_blank" rel="noopener noreferrer">
                <img src={imgs[9]} alt="devrev" />
              </a>
            </div>
          </Carousel>
        </div>
      </div>
      <div className="mt-10">
        <h1
          className="font-poppins text-center font-extrabold text-[#053859] my-5"
          style={{
            fontSize: "clamp(30px, 2.8vw, 5vw)",
            lineHeight: "clamp(40px, 3.5vw, 4vw)",
          }}
        >
          Our Graduates Pathway To Success
        </h1>
        <div className="flex items-center justify-center flex-wrap my-10 px-10">
          {/* First scrolling row */}
          <div className="relative overflow-hidden w-full h-[175px]">
            <div className="flex animate-bounce-x my-10">
              {logoArray1.concat(logoArray1).map((elem, index) => (
                <img
                  key={index}
                  src={elem}
                  alt={`Logo ${index}`}
                  className="h-[125px] w-[200px] p-[15px] rounded-lg shadow-md grayscale mx-[15px]"
                />
              ))}
            </div>
          </div>
          {/* Second scrolling row */}
          <div className="relative overflow-hidden w-full h-[175px] mt-6">
            <div className="flex animate-bounce-y py-5">
              {logoArray2.concat(logoArray2).map((elem, index) => (
                <img
                  key={index}
                  src={elem}
                  alt={`Logo ${index}`}
                  className="h-[125px] w-[200px] p-[15px] rounded-lg shadow-md grayscale mx-[15px]"
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="Placements">
        <h1 style={{ textAlign: "center" }}>
          Full Stack Web Development Using MERN Stack In 15 Days, Students
          Momentous Transformations
        </h1>
        <Graph data={graphProp} />
      </div>
    </div>
  );
};

export default Fifth;
