import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Navigation } from "swiper/modules";
import { mern1, js1 } from "../../Assets";

import "./blogSection.css";

const BlogSection = (props) => {
  console.log(props.blogs.LinkedIn[1].testBlog);

  const blogs = props.blogs.LinkedIn[1].testBlog;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleBlogClick = (link) => {
    window.open(link, "_blank");
  };

  return (
    <div className="blogs mt-10">
      <h1 className="blogs-box">Blogs</h1>
      <Swiper
        slidesPerView={1}
        breakpoints={{
          640: { slidesPerView: 1 },
          768: { slidesPerView: 2 },
          1024: { slidesPerView: 4 },
        }}
        pagination={{ clickable: true }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="card-swiper"
      >
        {blogs.map((blog, index) => (
          <SwiperSlide key={index}>
            <div
              style={{
                marginBottom: isMobile ? "0px" : "60px",
                padding: "10px",
              }}
            >
              <div className="card4" onClick={() => handleBlogClick(blog.link)}>
                <div className="card-image">
                  <img
                    src={blog.img.filename || "default-image.jpg"}
                    alt="Blog"
                  />
                </div>
                <div style={{ padding: "10px" }}>
                  <div className="blog-box">{blog.category}</div>
                  <div className="blogContent">{blog.title}</div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <div className="blog-author">{blog.date}</div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default BlogSection;
