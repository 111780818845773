export const logo = require('./Logo.jpg');
export const whiteLogo = require('./Logo-white.png')
export const yellowLogo = require("./Logo-yellow.png")
export const illustration = require('./illustration.png')
export const internship = require('./internship.png')
export const scrum = require('./scrum.png')
export const customer = require('./Customer.png')
export const student_1 = require('./student_1.png');
export const student_2 = require('./student_2.png');
export const pathways = require('./pathways.png');
export const graph_1 = require('./graph_1.png')
export const graph_2 = require('./graph_2.png')
export const AboutIllustration = require("./About.png");
export const PlacementIllustration = require('./Placement.png');
export const AchievementsIllustration = require("./Achievements.png");
export const About1 = require('./About1.png');
export const About2 = require('./About2.png');
export const About3 = require('./About3.png');
export const Project1 = require('./Project1.png');
export const Project2 = require('./Project2.png');
export const Project3 = require('./Project3.png');
export const Project4 = require('./Project4.png');
export const Project5 = require('./Project5.png');
export const Project6 = require('./Project6.png');
export const Project7 = require('./Project7.png');
export const Project8 = require('./Project8.png');
export const Project9 = require('./Project9.png');
export const Achievement = require("./Achievement.png")
export const achievebg = require('./achievebg.png')
export const ProgramIllustration = require("./Program.png")
export const Program21 = require("./Program-21.png")
export const Program22 = require("./Program-22.png")
export const Program23 = require("./Program-23.png")
export const Program24 = require("./Program-24.png")
export const Program25 = require("./Program-25.png")
export const Program26 = require("./Program-26.png")
export const Program31 = require("./Program-31.png")
export const Program32 = require("./Program-32.png")
export const Program33 = require("./Program-33.png")
export const Program34 = require("./Program-34.png")
export const Program35 = require("./Program-35.png")
export const Program36 = require("./Program-36.png")
export const ProgramMask = require("./Mask.png")
export const PlacementGraph = require("./PlacementGraph.png");
export const Student1 = require('./Student1.png')
export const Student2 = require('./Student2.png')
export const download = require('./download.png')
export const Extensive1 = require('./Home_program/Extensive1.png');
export const Extensive2 = require('./Home_program/Extensive2.png');
export const Extensive3 = require('./Home_program/Extensive3.png');
export const Extensive4 = require('./Home_program/Extensive4.png');
export const Extensive5 = require('./Home_program/Extensive5.png');
export const Extensive6 = require('./Home_program/Extensive6.png');
export const Bubble_bg = require('./Bubble_bg.png')
export const College1 = require('./Home_college/College1.png');
export const College2 = require('./Home_college/College2.png');
export const College3 = require('./Home_college/College3.png');
export const College4 = require('./Home_college/College4.png');
export const College5 = require('./Home_college/College5.png');
export const College6 = require('./Home_college/College6.jpeg');
export const Men = require('./Gender/Men.png');
export const Women = require('./Gender/Women.png');
export const Gallery1 = require('./Home_Gallery/Gallery1.png')
export const Gallery2 = require('./Home_Gallery/Gallery2.png')
export const Gallery3 = require('./Home_Gallery/Gallery3.png')
export const Gallery4 = require('./Home_Gallery/Gallery4.png')
export const Gallery5 = require('./Home_Gallery/Gallery5.png')
export const Gallery6 = require('./Home_Gallery/Gallery6.png')
export const Gallery7 = require('./Home_Gallery/Gallery7.png')
export const Gallery8 = require('./Home_Gallery/Gallery8.png')
export const Gallery9 = require('./Home_Gallery/Gallery9.png')
export const Gallery10 = require('./Home_Gallery/Gallery10.png')
export const Linkedin1 = require('./Linkedin/Linkedin1.png');
export const Linkedin2 = require('./Linkedin/Linkedin2.png');
export const Linkedin3 = require('./Linkedin/Linkedin3.png');
export const Linkedin4 = require('./Linkedin/Linkedin4.png');
export const Linkedin5 = require('./Linkedin/Linkedin5.png');
export const Linkedin6 = require('./Linkedin/Linkedin6.png');
export const Linkedin7 = require('./Linkedin/Linkedin7.jpg');
export const Linkedin8 = require('./Linkedin/Linkedin8.jpg');
export const Linkedin9 = require('./Linkedin/Linkedin9.jpg');
export const Linkedin10 = require('./Linkedin/Linkedin10.png');
export const Skillhub = require('./Skillhub.png')
export const Linkedin = require('./Media/Linkedin.png')
export const Insta = require('./Media/Insta.png')
export const Celeb1 = require('./About_Celeb/Celeb1.png');
export const Celeb2 = require('./About_Celeb/Celeb2.png');
export const InviteBT = require('./InviteBT.png');
export const Roadmap = require('./Roadmap.png');
export const sed = require('./sde.png');
export const sde2 = require('./SDE21.png');
export const htmli = require('./htmlimg.png');
export const linklogo  = require('./linnklogo.png');
export const linkiicon = require('./Linkicon.png');
export const  jsonimg = require('./jsonimage.jpg');
export const json2 = require('./json2.png');
export const feedbackimg = require('./feedbackimg.png');    
export const json3 = require('./json3.png');
export const json4 = require('./json4.png');
export const json5 = require('./json5.png');
export const json51 = require('./json52.png');
export const mern1 = require('./Mern2.png');
export const js1 = require('./js2.jpg');
export const feedimg = require('./feedimg.png');